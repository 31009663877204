/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { useTheme } from "emotion-theming"
const Textarea = ({ children, id, placeholder, required, onChange, value }) => {
  const theme = useTheme()
  const styles = css`
    font-family: "Product Sans", sans-serif;

    label,
    textarea {
      display: block;
      width: 100%;
    }

    label {
      margin-bottom: ${theme.margin[100]};
      font-size: 15px;
      font-weight: 600;
    }

    textarea {
      font: inherit;
      appearance: none;
      font-size: 15px;
      border-radius: 4px;
      border: 1px solid grey;
      min-height: 120px;
      line-height: 1.2;
      padding: ${theme.padding[100]};

      @media screen and (max-width: 480px) {
        font-size: 16px;
        padding: ${theme.padding[200]};
      }
    }
  `
  return (
    <div css={styles}>
      {children && <label htmlFor={id}>{children}</label>}
      <textarea
        name={id}
        id={id}
        placeholder={placeholder}
        required={required}
        onChange={onChange}
        value={value}
      />
    </div>
  )
}
export { Textarea }
