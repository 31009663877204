/** @jsx jsx */
import { jsx } from "@emotion/core"
const IconInsurance = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.719 9.17924L21.7197 9.17959C21.9658 9.29118 22.1892 9.59537 22.1892 9.99951C22.1892 18.2443 17.5596 22.7095 14.8407 23.9456C14.6806 24.0181 14.5055 24.0181 14.3454 23.9458C10.9201 22.3879 7 17.4189 7 9.99951C7 9.59615 7.22253 9.29257 7.47112 9.18046L7.4738 9.17924L14.348 6.05462L14.3486 6.05435C14.4301 6.01754 14.514 6 14.5964 6C14.6787 6 14.7626 6.01754 14.8442 6.05435L14.8448 6.05462L21.719 9.17924ZM14.5964 5C14.3696 5 14.1451 5.04879 13.9357 5.14357L7.06 8.26888C6.41899 8.55797 6 9.24163 6 9.99951C6 17.7542 10.1003 23.1141 13.9321 24.8564C14.3547 25.0479 14.8309 25.0479 15.2535 24.8564C18.3225 23.4618 23.1892 18.6449 23.1892 9.99951C23.1892 9.24163 22.7702 8.55797 22.1328 8.26888L15.2571 5.14357C15.0476 5.04879 14.8231 5 14.5964 5ZM17.1978 13.5H17.5934C18.0302 13.5 18.3846 13.8359 18.3846 14.25V17.25C18.3846 17.6641 18.0302 18 17.5934 18H11.7912C11.3544 18 11 17.6641 11 17.25V14.25C11 13.8359 11.3544 13.5 11.7912 13.5H12.1868V12.375C12.1868 11.0656 13.311 10 14.6923 10C16.0736 10 17.1978 11.0656 17.1978 12.375V13.5ZM13.5055 12.375V13.5H15.8791V12.375C15.8791 11.7547 15.3467 11.25 14.6923 11.25C14.0379 11.25 13.5055 11.7547 13.5055 12.375Z"
        fill="#3A3956"
      />
    </svg>
  )
}
export { IconInsurance }
