/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { useTheme } from "emotion-theming"
import { useIntl } from "gatsby-plugin-intl"

import { Wrapper, LangSwitcher } from "components"
import { useModal } from "context"

const Header = () => {
  const theme = useTheme()
  const styles = css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 3;
    pointer-events: none;

    .W {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: 0 auto;

      .KeyCTA {
        display: flex;
        justify-content: flex-end;

        li {
          padding: ${theme.padding[200]};
          pointer-events: all;
          width: 160px;
          text-align: center;
          font-size: 14px;
          color: white;

          &.Lang {
            width: auto;
            background: ${theme.color.darkpurple};
            padding: ${theme.padding[100]} ${theme.padding[200]};
            display: flex;
            align-items: center;
            justify-content: center;
            border-right: 1px solid rgba(255, 255, 255, 0.1);
          }

          @media screen and (max-width: 740px) {
            min-width: calc(50vw - 31px);
            width: auto;

            &.Lang {
              min-width: 62px;
              background: #232323;
              border-right: none;
            }
          }

          &:nth-of-type(2) {
            background: ${theme.color.darkpurple};
          }

          &:last-of-type {
            background: ${theme.color.red};
          }
        }
      }

      .SubLinks {
        display: flex;
        pointer-events: all;
        padding: ${theme.padding[100]} 0;
        background: white;
        box-shadow: 0 0px 30px rgba(0, 0, 0, 0.05);

        @media screen and (max-width: 740px) {
          justify-content: center;
        }

        @media screen and (max-width: 540px) {
          display: none;
        }

        li {
          font-size: 14px;
          padding: ${theme.padding[100]} ${theme.padding[200]};
          background: white;
          color: ${theme.color.lightpurple};

          a {
            text-decoration: none;
          }

          &:hover {
            text-decoration: underline;
            color: ${theme.color.red};
            cursor: pointer;
          }

          &:not(:last-of-type) {
            border-right: 1px solid rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  `

  const intl = useIntl()
  const { setActive } = useModal().setState

  return (
    <header css={styles}>
      <Wrapper>
        <nav>
          <ul className="KeyCTA">
            <li className="Lang">
              <LangSwitcher />
            </li>
            <li onClick={() => setActive(true)}>
              {intl.formatMessage({ id: "header.viewing" })}
            </li>
            <li onClick={() => setActive(true)}>
              {intl.formatMessage({ id: "header.book" })}
            </li>
          </ul>
          <ul className="SubLinks">
            <li>
              <a href="#welcome">{intl.formatMessage({ id: "header.why" })}</a>
            </li>
            <li>
              <a href="#rooms">{intl.formatMessage({ id: "header.rooms" })}</a>
            </li>
            <li>
              <a href="#explore">
                {intl.formatMessage({ id: "header.explore" })}
              </a>
            </li>
            <li onClick={() => setActive(true)}>
              {intl.formatMessage({ id: "header.question" })}
            </li>
            <li onClick={() => setActive(true)}>
              {intl.formatMessage({ id: "header.contact" })}
            </li>
          </ul>
        </nav>
      </Wrapper>
    </header>
  )
}

export { Header }
