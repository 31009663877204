/** @jsx jsx */
import { jsx } from "@emotion/core"
const IconTV = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8529 18.675V20.95H9.41765V21.3H20.7V20.95H15.2647V18.675H22.7588C22.9412 18.675 23.1115 18.6048 23.2333 18.4871C23.3544 18.3702 23.4176 18.217 23.4176 18.0625V9.3125C23.4176 9.15804 23.3544 9.00479 23.2333 8.8879C23.1115 8.77022 22.9412 8.7 22.7588 8.7H7.35882C7.17641 8.7 7.00615 8.77022 6.88431 8.8879C6.7633 9.00479 6.7 9.15804 6.7 9.3125V18.0625C6.7 18.217 6.7633 18.3702 6.88431 18.4871C7.00615 18.6048 7.17641 18.675 7.35882 18.675H14.8529ZM23.0059 18.325H7.11176V9.05H23.0059V18.325ZM22.7588 8C23.1192 8 23.4648 8.13828 23.7197 8.38442C23.9745 8.63056 24.1176 8.9644 24.1176 9.3125V18.0625C24.1176 18.4106 23.9745 18.7444 23.7197 18.9906C23.4648 19.2367 23.1192 19.375 22.7588 19.375H15.9647V20.25H20.9471C21.0672 20.25 21.1824 20.2961 21.2673 20.3781C21.3523 20.4602 21.4 20.5715 21.4 20.6875V21.5625C21.4 21.6785 21.3523 21.7898 21.2673 21.8719C21.1824 21.9539 21.0672 22 20.9471 22H9.17059C9.05046 22 8.93525 21.9539 8.85031 21.8719C8.76537 21.7898 8.71765 21.6785 8.71765 21.5625V20.6875C8.71765 20.5715 8.76537 20.4602 8.85031 20.3781C8.93525 20.2961 9.05046 20.25 9.17059 20.25H14.1529V19.375H7.35882C6.99844 19.375 6.65282 19.2367 6.39799 18.9906C6.14316 18.7444 6 18.4106 6 18.0625V9.3125C6 8.9644 6.14316 8.63056 6.39799 8.38442C6.65282 8.13828 6.99844 8 7.35882 8H22.7588ZM22.3059 17.625V9.75H7.81176V17.625H22.3059Z"
        fill="#3A3956"
      />
    </svg>
  )
}
export { IconTV }
