/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { useTheme } from "emotion-theming"
import { Link } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import FlagCN from "images/flag-cn.svg"
import FlagGB from "images/flag-gb.svg"
const LangSwitcher = () => {
  const theme = useTheme()
  const styles = css`
    display: flex;
    justify-content: flex-end;
    align-items: center;

    img {
      border-radius: 2px;
      display: block;
    }
  `
  const { locale } = useIntl()
  return (
    <div css={styles}>
      {locale === "en" ? (
        <Link to="/zh">
          <img src={FlagCN} alt="" />
        </Link>
      ) : (
        <Link to="/en">
          <img src={FlagGB} alt="" />
        </Link>
      )}
    </div>
  )
}
export { LangSwitcher }
