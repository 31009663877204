/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { useTheme } from "emotion-theming"
import { useIntl } from "gatsby-plugin-intl"
const GallerySidebar = ({ children }) => {
  const theme = useTheme()
  const styles = css`
    margin-left: 120px;
    max-width: 440px;
    padding-top: 80px;

    @media screen and (max-width: 1198px) {
      margin-left: 80px;
      padding-right: ${theme.padding[300]};
      padding-top: ${theme.padding[500]};
    }

    @media screen and (max-width: 960px) {
      margin-left: 80px;
      padding-right: ${theme.padding[300]};
      padding-top: ${theme.padding[300]};
    }

    @media screen and (max-width: 840px) {
      margin-left: 0;
    }

    @media screen and (max-width: 720px) {
      max-width: 100%;
      margin-left: 0;
      padding-top: ${theme.padding[200]};
      padding-right: 0;
    }

    h4 {
      max-width: 340px;
      line-height: 1.2;

      @media screen and (max-width: 1198px) {
        max-width: 100%;
      }

      @media screen and (max-width: 720px) {
        display: none;
      }
    }

    p {
      margin-bottom: ${theme.margin[300]};
      max-width: 365px;
      font-size: 16px;

      @media screen and (max-width: 1198px) {
        max-width: 100%;
      }

      @media screen and (max-width: 720px) {
        display: none;
      }
    }

    .buttons {
      display: flex;
      justify-content: space-between;

      .CTA {
        appearance: none;
        background: ${theme.color.red};
        border: none;
        color: white;
        font: inherit;
        font-family: "Product Sans", sans-serif;
        border-radius: 4px;
        font-size: 16px;
        padding: ${theme.padding[100]} ${theme.padding[300]}
          ${theme.padding[100]} ${theme.padding[200]};
        display: flex;
        align-items: center;

        img {
          display: block;
          width: 30px;
          margin-right: ${theme.margin[200]};
        }

        @media screen and (max-width: 720px) {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .nextprev {
        width: 109px;
        height: 44px;
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 720px) {
          display: none;
        }

        button {
          background: transparent;
          appearance: none;
          border: 1px solid grey;
          border-radius: 4px;
          height: 44px;
          color: white;
          font-size: 18px;
          font-family: "Product Sans", sans-serif;
          width: 50px;
          outline: none;

          &:hover {
            background: ${theme.color.red};
            border: 1px solid ${theme.color.red};
            color: white;
            cursor: pointer;
          }
        }
      }
    }
  `
  const intl = useIntl()
  return (
    <div css={styles}>
      <h4>{intl.formatMessage({ id: "gallery.galleryTitle" })}</h4>
      <p>{intl.formatMessage({ id: "gallery.galleryDescription" })}</p>
      <div className="buttons">{children}</div>
    </div>
  )
}
export { GallerySidebar }
