/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { useTheme } from "emotion-theming"
import { useIntl } from "gatsby-plugin-intl"
import { useEffect, useState } from "react"

const RoomDescription = ({ data }) => {
  const theme = useTheme()
  const styles = css`
    padding: ${theme.padding[200]} ${theme.padding[300]};
    height: auto;

    &[data-loaded="false"] {
      opacity: 0;
    }

    &[data-loaded="true"] {
      opacity: 1;
      transition: opacity 0.2s ease;
    }

    p {
      font-size: 16px;
      line-height: 22px;
      color: ${theme.color.lightpurple};

      @media screen and (max-width: 540px) {
        font-size: 15px;
        line-height: 1.5;
      }

      @media screen and (max-width: 360px) {
        font-size: 14px;
        line-height: 1.4;
      }
    }

    .features {
      margin-top: ${theme.margin[300]};
      margin-bottom: ${theme.margin[100]};
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: ${theme.margin[200]};
      row-gap: ${theme.margin[200]};
      font-size: 14px;
      font-weight: 600;

      @media screen and (max-width: 1080px) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media screen and (max-width: 820px) {
        grid-template-columns: repeat(4, 1fr);
      }

      @media screen and (max-width: 680px) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media screen and (max-width: 540px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media screen and (max-width: 380px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  `

  const intl = useIntl()
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setLoaded(false)
    setTimeout(() => setLoaded(true), 30)
  }, [data])

  return (
    <div css={styles} data-loaded={loaded}>
      <p>{intl.formatMessage({ id: data.description })}</p>
      <ul className="features">
        {data.features.map(feature => (
          <li key={feature}>{intl.formatMessage({ id: feature })}</li>
        ))}
      </ul>
    </div>
  )
}
export { RoomDescription }
