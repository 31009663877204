/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { useTheme } from "emotion-theming"
import { useIntl } from "gatsby-plugin-intl"

const RoomCard = ({ name, price }) => {
  const theme = useTheme()
  const styles = css`
    margin-right: ${theme.margin[100]};
    margin-bottom: ${theme.margin[200]};

    .content {
      padding: ${theme.padding[200]} ${theme.padding[300]} ${theme.padding[100]};
      border-radius: 4px;
      background: white;
      text-align: center;
      position: relative;
      font-family: "Product Sans", sans-serif;

      &::before {
        content: "";
        display: block;
        height: 16px;
        width: 16px;
        background: white;
        position: absolute;
        bottom: -7px;
        border-radius: 2px;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 2;
        transform: rotateZ(45deg) translateY(-13px) scale(0);
      }

      .title {
        font-size: 17px;
        font-weight: 600;
        margin-bottom: 9px;
      }

      .price {
        font-size: 14px;
        color: ${theme.color.red};
        font-weight: 600;
        margin-bottom: 5px;
      }

      .sold {
        background: ${theme.color.red};
        color: white;
        font-size: 12px;
        font-weight: 600;
        padding: 6px 6px 5px;
        position: relative;
        z-index: 2;
        border-radius: 2px;
        max-width: 80px;
        margin: auto;
        top: -5px;
        margin-bottom: -5px;
      }
    }
  `
  const intl = useIntl()

  return (
    <div className="RoomCard" css={styles}>
      <div className="content">
        <div className="title">{intl.formatMessage({ id: name })}</div>
        {!price ? (
          <div className="sold">
            {intl.formatMessage({ id: "rooms.misc.soldOut" })}
          </div>
        ) : (
          <div className="price">
            {intl.formatMessage({ id: "rooms.misc.from" })} £{price}{" "}
            {intl.formatMessage({ id: "rooms.misc.perWeek" })}
          </div>
        )}
      </div>
    </div>
  )
}
export { RoomCard }
