/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { useTheme } from "emotion-theming"
const Wrapper = ({ children }) => {
  const theme = useTheme()
  const styles = css`
    max-width: ${theme.WRAPPER_WIDTH};
  `
  return (
    <div className="W" css={styles}>
      {children}
    </div>
  )
}
export { Wrapper }
