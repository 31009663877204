/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { useTheme } from "emotion-theming"
import { useState, useEffect } from "react"
import { useIntl } from "gatsby-plugin-intl"

import IconFloorplan from "./icon-floorplan.svg"

const RoomDetailFooter = ({ name, price, onClick, isLayout }) => {
  const theme = useTheme()
  const styles = css`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: ${theme.padding[300]};
    color: white;
    display: flex;
    align-items: flex-end;
    font-family: "Product Sans", sans-serif;
    background: linear-gradient(
      360deg,
      rgba(35, 35, 35, 1) 0%,
      rgba(35, 35, 35, 0) 100%
    );
    transition: background 0.2s ease;
    height: 100%;
    pointer-events: none;

    &[data-loaded="false"] {
      .l {
        opacity: 0;
      }
    }

    &[data-loaded="true"] {
      .l {
        opacity: 1;
        transition: opacity 0.2s ease, background 0.2s ease;
      }
    }

    &[data-is-layout="true"] {
      background: linear-gradient(
        360deg,
        rgba(35, 35, 35, 0.4) 0%,
        rgba(35, 35, 35, 0) 50%
      );
      transition: opacity 0.2s ease, background 0.2s ease;
    }

    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    .title {
      font-size: 34px;
      font-weight: 600;
      margin-bottom: 4px;

      @media screen and (max-width: 1080px) {
        font-size: 20px;
      }

      @media screen and (max-width: 960px) {
        font-size: 18px;
      }

      @media screen and (max-width: 820px) {
        font-size: 34px;
      }

      @media screen and (max-width: 720px) {
        font-size: 30px;
      }

      @media screen and (max-width: 660px) {
        font-size: 20px;
      }

      @media screen and (max-width: 390px) {
        font-size: 18px;
      }

      @media screen and (max-width: 360px) {
        line-height: 1;
        font-size: 14px;
      }
    }

    .price {
      font-size: 20px;
      font-weight: 600;

      @media screen and (max-width: 1080px) {
        font-size: 14px;
      }

      @media screen and (max-width: 820px) {
        font-size: 20px;
      }

      @media screen and (max-width: 720px) {
        font-size: 18px;
      }

      @media screen and (max-width: 660px) {
        font-size: 14px;
      }

      @media screen and (max-width: 360px) {
        font-size: 11px;
      }
    }

    button {
      appearance: none;
      border: none;
      background: ${theme.color.red};
      border-radius: 4px;
      padding: ${theme.padding[100]} ${theme.padding[300]} ${theme.padding[100]}
        ${theme.padding[200]};
      font: inherit;
      font-size: 15px;
      display: flex;
      align-items: center;
      color: white;
      font-weight: 600;
      pointer-events: all;
      cursor: pointer;

      img {
        display: block;
        position: relative;
        top: -2px;
        width: 22px;
        height: 22px;
        margin-right: 12px;
      }

      @media screen and (max-width: 420px) {
        height: 44px;
        width: 44px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          margin-right: 0;
          top: -1px;
        }
        span {
          display: none;
        }
      }
    }
  `

  const intl = useIntl()
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setLoaded(false)
    setTimeout(() => setLoaded(true), 30)
  }, [name])

  return (
    <div css={styles} data-loaded={loaded} data-is-layout={isLayout}>
      <div className="info">
        <div className="l">
          <div className="title">{intl.formatMessage({ id: name })}</div>
          <div className="price">
            {price
              ? `${intl.formatMessage({
                  id: "rooms.misc.from",
                })} £${price} ${intl.formatMessage({
                  id: "rooms.misc.perWeek",
                })}`
              : intl.formatMessage({ id: "rooms.misc.soldOut" })}
          </div>
        </div>
        <div className="r">
          <button onClick={onClick}>
            <img src={IconFloorplan} alt="" />
            <span>{intl.formatMessage({ id: "rooms.misc.layoutCTA" })}</span>
          </button>
        </div>
      </div>
    </div>
  )
}
export { RoomDetailFooter }
