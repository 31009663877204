import React, { useContext, createContext, useState } from "react"
import { Modal as ModalView } from "components"

const modalContext = createContext()

export function Modal({ children }) {
  const modal = useProvideModal()
  return (
    <modalContext.Provider value={modal}>
      {children}
      <ModalView active={modal.active} />
    </modalContext.Provider>
  )
}

export const useModal = () => {
  return useContext(modalContext)
}

function useProvideModal() {
  const [active, setActive] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  return {
    active,
    submitted,
    setState: {
      setActive,
      setSubmitted,
    },
  }
}
