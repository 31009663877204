/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { useTheme } from "emotion-theming"
import { useIntl } from "gatsby-plugin-intl"
import { useModal } from "context"

import Tick from "./tick.svg"

const Confirmation = () => {
  const theme = useTheme()
  const styles = css`
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    img {
      margin-bottom: ${theme.margin[200]};
    }

    button {
      appearance: none;
      font: inherit;
      color: white;
      width: 220px;
      padding: 12px;
      border-radius: 4px;
      font-size: 16px;
      background: ${theme.color.red};
      border: 1px solid ${theme.color.red};
    }
  `
  const intl = useIntl()
  const { setActive } = useModal().setState

  return (
    <div css={styles}>
      <div className="c">
        <img src={Tick} alt="" />
        <h5>{intl.formatMessage({ id: "form.confirmation.title" })}</h5>
        <p>{intl.formatMessage({ id: "form.confirmation.description" })}</p>
        <button onClick={() => setActive(false)}>
          {intl.formatMessage({ id: "form.close" })}
        </button>
      </div>
    </div>
  )
}
export { Confirmation }
