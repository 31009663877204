/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { useTheme } from "emotion-theming"
import { Wrapper } from "components"
const CarouselHeader = ({ title, children, buttons, type }) => {
  const theme = useTheme()
  const styles = css`
    padding: 0 ${theme.padding[300]};

    @media screen and (max-width: 380px) {
      padding: 0 ${theme.padding[200]};
    }

    .W {
      margin: auto;
      color: ${type === "light" ? theme.color.darkpurple : "white"};
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media screen and (max-width: 740px) {
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
      }

      @media screen and (max-width: 640px) {
        display: block;
      }

      h4 {
        font-size: 28px;
        line-height: 34px;
        max-width: ${children ? "25%" : "50%"};

        @media screen and (max-width: 740px) {
          max-width: 40%;
          padding-right: ${theme.margin[300]};
          font-size: 24px;
          line-height: 1.2;
        }

        @media screen and (max-width: 640px) {
          max-width: unset;
        }

        @media screen and (max-width: 380px) {
          padding-right: 0;
        }

        u {
          text-decoration: none;
          position: relative;
          white-space: nowrap;

          span {
            z-index: 2;
            position: relative;
          }

          &::before {
            display: block;
            content: "";
            height: 6px;
            width: calc(100% + 8px);
            z-index: 1;
            position: absolute;
            bottom: 4px;
            left: -3px;
            background: ${theme.color.red};
          }
        }
      }

      p {
        font-family: "Product Sans", sans-serif;
        font-size: 16px;
        line-height: 28px;
        max-width: 45%;

        @media screen and (max-width: 740px) {
          flex: 1 0 auto;
          max-width: 60%;
          font-size: 16px;
          line-height: 1.3;
        }

        @media screen and (max-width: 640px) {
          max-width: unset;
          margin-bottom: ${theme.margin[400]};
        }

        @media screen and (max-width: 540px) {
          font-size: 15px;
          line-height: 1.4;
        }

        @media screen and (max-width: 360px) {
          font-size: 14px;
          line-height: 1.4;
        }
      }

      .prevnext {
        width: 109px;
        height: 44px;
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 740px) {
          margin: ${theme.margin[200]} 0;
        }

        @media screen and (max-width: 640px) {
          width: 100%;
        }

        @media screen and (max-width: 540px) {
          display: none;
        }

        button {
          background: transparent;
          appearance: none;
          border: 1px solid grey;
          border-radius: 4px;
          height: 44px;
          color: ${type === "light" ? theme.color.darkpurple : "white"};
          font-size: 18px;
          font-family: "Product Sans", sans-serif;
          width: 50px;
          outline: none;

          &:hover {
            background: ${theme.color.red};
            border: 1px solid ${theme.color.red};
            color: white;
            cursor: pointer;
          }
        }
      }
    }
  `
  return (
    <div css={styles}>
      <Wrapper>
        {title}
        {children && <p>{children}</p>}
        {buttons}
      </Wrapper>
    </div>
  )
}
export { CarouselHeader }
