/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { useTheme } from "emotion-theming"
import { useIntl } from "gatsby-plugin-intl"

import IconBike from "./icon-bike.svg"
import IconShoe from "./icon-shoe.svg"

const DistanceCard = ({ name, image, walk, cycle }) => {
  const theme = useTheme()
  const styles = css`
    margin-right: ${theme.margin[200]};
    height: 260px;
    border-radius: 4px;
    overflow: hidden;
    background: white;

    .image {
      height: 150px;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .content {
      padding: ${theme.padding[200]};
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 110px;
      font-family: "Product Sans", sans-serif;

      .title {
        font-size: 18px;
        font-weight: 600;
        line-height: 1.2;
      }

      .distances {
        display: flex;
        margin-top: auto;

        li {
          margin-right: ${theme.margin[200]};
          display: flex;
          align-items: center;
          font-size: 16px;

          img {
            display: block;
            margin-right: ${theme.margin[100]};
          }
        }
      }
    }
  `
  const intl = useIntl()
  return (
    <div css={styles}>
      <div className="image">
        <img loading="lazy" src={image} alt="" />
      </div>
      <div className="content">
        <div className="title">{intl.formatMessage({ id: name })}</div>
        <ul className="distances">
          <li>
            <img src={IconBike} alt="" />
            {cycle} {intl.formatMessage({ id: "distance.mins" })}
          </li>
          <li>
            <img src={IconShoe} alt="" />
            {walk} {intl.formatMessage({ id: "distance.mins" })}
          </li>
        </ul>
      </div>
    </div>
  )
}
export { DistanceCard }
