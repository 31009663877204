/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { useTheme } from "emotion-theming"
import { useIntl } from "gatsby-plugin-intl"
import { useModal } from "context"

const TenantCard = ({ duration, price, start }) => {
  const theme = useTheme()
  const styles = css`
    border-bottom: 1px solid #89899d;
    padding: ${theme.padding[200]} 0;
    font-family: "Product Sans", sans-serif;

    &:last-of-type {
      border-bottom: 0;
    }

    .title {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: ${theme.margin[100]};
    }

    .info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: ${theme.margin[100]};

      .price {
        background: ${theme.color.darkpurple};
        color: white;
        font-size: 14px;
        line-height: 1;
        font-weight: 600;
        padding: ${theme.padding[100]} ${theme.padding[300]};
        border-radius: 4px;
      }

      .sold {
        background: rgba(0, 0, 0, 0.1);
        color: rgba(0, 0, 0, 0.5);
        font-size: 14px;
        line-height: 1;
        font-weight: 600;
        padding: ${theme.padding[100]} ${theme.padding[300]};
        border-radius: 4px;
      }

      button {
        appearance: none;
        border: none;
        background: ${theme.color.red};
        color: white;
        font: inherit;
        font-size: 16px;
        padding: ${theme.padding[200]} ${theme.padding[500]};
        border-radius: 4px;
        font-weight: 600;

        &.wait {
          background: ${theme.color.lightpurple};
        }
      }
    }

    .start {
      font-size: 14px;
      opacity: 0.6;
    }
  `
  const intl = useIntl()
  const { setActive } = useModal().setState

  return (
    <div className="TenantCard" css={styles}>
      <div className="title">
        {duration} {intl.formatMessage({ id: "rooms.misc.tenancyWeek" })}
      </div>
      <div className="info">
        {price ? (
          <div className="price">
            £{price} {intl.formatMessage({ id: "rooms.misc.pppw" })}
          </div>
        ) : (
          <div className="sold">
            {intl.formatMessage({ id: "rooms.misc.soldOut" })}
          </div>
        )}
        {price ? (
          <button onClick={() => setActive(true)}>
            {intl.formatMessage({ id: "rooms.misc.book" })}
          </button>
        ) : (
          <button className="wait" onClick={() => setActive(true)}>
            {intl.formatMessage({ id: "rooms.misc.waitList" })}
          </button>
        )}
      </div>
      <div className="start">
        {intl.formatMessage({ id: "rooms.misc.tenancyStart" })} {start}
      </div>
    </div>
  )
}
export { TenantCard }
