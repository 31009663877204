/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { useTheme } from "emotion-theming"
import { Field, ErrorMessage } from "formik"
const Input = ({
  children,
  id,
  placeholder,
  required,
  type,
  onChange,
  value,
}) => {
  const theme = useTheme()
  const styles = css`
    font-family: "Product Sans", sans-serif;

    label,
    input {
      display: block;
      width: 100%;
    }

    label {
      margin-bottom: ${theme.margin[100]};
      font-size: 15px;
      font-weight: 600;
    }

    input {
      font: inherit;
      appearance: none;
      font-size: 15px;
      border-radius: 4px;
      border: 1px solid grey;
      padding: ${theme.padding[100]};

      @media screen and (max-width: 480px) {
        font-size: 16px;
        padding: ${theme.padding[200]};
      }
    }

    .err {
      background: rgba(255, 0, 0, 0.4);
      color: rgba(180, 0, 0, 1);
      border: 1px solid rgba(180, 0, 0, 0.2);
      font-size: 13px;
      padding: 6px 6px;
      border-radius: 2px;
      margin-top: 4px;
    }
  `
  return (
    <div css={styles}>
      {children && <label htmlFor={id}>{children}</label>}
      <Field
        onChange={onChange}
        value={value}
        type={type || "text"}
        id={id}
        name={id}
        placeholder={placeholder}
        required={required}
      />
      <ErrorMessage name={id} component="div" className="err" />
    </div>
  )
}
export { Input }
