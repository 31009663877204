/** @jsx jsx */
import { jsx } from "@emotion/core"
const IconSafe = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M24.4961 10.4668C24.3181 10.4668 24.1738 10.611 24.1738 10.7891V22.8544C24.1738 23.0488 24.0156 23.207 23.8211 23.207H6.17886C5.98438 23.207 5.82617 23.0488 5.82617 22.8544V21.0586C5.82617 20.8806 5.68188 20.7363 5.50391 20.7363C5.32593 20.7363 5.18164 20.8806 5.18164 21.0586V22.8544C5.18164 23.4043 5.62899 23.8516 6.17886 23.8516H7.10849V25.248C7.10849 25.6627 7.44584 26 7.86044 26H11.8995C12.3141 26 12.6515 25.6627 12.6515 25.248V23.8516H17.2061V25.248C17.2061 25.6627 17.5435 26 17.9581 26H21.9972C22.4118 26 22.7491 25.6627 22.7491 25.248V23.8516H23.8212C24.3711 23.8516 24.8184 23.4043 24.8184 22.8544V10.7891C24.8184 10.611 24.6741 10.4668 24.4961 10.4668ZM12.0069 25.248C12.0069 25.3073 11.9587 25.3555 11.8995 25.3555H7.86044C7.80123 25.3555 7.75302 25.3073 7.75302 25.248V23.8516H12.0069V25.248ZM22.1046 25.248C22.1046 25.3073 22.0564 25.3555 21.9972 25.3555H17.9581C17.8989 25.3555 17.8507 25.3073 17.8507 25.248V23.8516H22.1046V25.248Z"
          fill="#3A3956"
        />
        <path
          d="M23.8211 4H6.17886C5.62899 4 5.18164 4.4473 5.18164 4.99718V19.4688C5.18164 19.6468 5.32593 19.791 5.50391 19.791C5.68188 19.791 5.82617 19.6468 5.82617 19.4688V4.99718C5.82617 4.80274 5.98438 4.64453 6.17886 4.64453H23.8212C24.0157 4.64453 24.1739 4.80274 24.1739 4.99718V9.24219C24.1739 9.42021 24.3182 9.56445 24.4961 9.56445C24.6741 9.56445 24.8184 9.42021 24.8184 9.24219V4.99718C24.8184 4.4473 24.371 4 23.8211 4V4Z"
          fill="#3A3956"
        />
        <path
          d="M6.61719 16.2099V18.2811C6.61719 18.812 7.00429 19.2538 7.51098 19.3399V20.4071C7.51098 20.9677 7.96709 21.4238 8.52771 21.4238H21.3298C21.8904 21.4238 22.3465 20.9677 22.3465 20.4071V7.44446C22.3465 6.88385 21.8904 6.42773 21.3298 6.42773H17.176C16.998 6.42773 16.8537 6.57198 16.8537 6.75C16.8537 6.92802 16.998 7.07227 17.176 7.07227H21.3298C21.535 7.07227 21.702 7.23924 21.702 7.44446V20.4071C21.702 20.6123 21.535 20.7793 21.3298 20.7793H8.52771C8.32249 20.7793 8.15551 20.6123 8.15551 20.4071V19.3399C8.6622 19.2538 9.0493 18.812 9.0493 18.2812V16.21C9.0493 15.6791 8.6622 15.2373 8.15551 15.1512V12.7004C8.6622 12.6143 9.0493 12.1725 9.0493 11.6417V9.57047C9.0493 9.03963 8.6622 8.59779 8.15551 8.51172V7.44446C8.15551 7.23924 8.32249 7.07227 8.52771 7.07227H15.3374C15.5153 7.07227 15.6596 6.92802 15.6596 6.75C15.6596 6.57198 15.5153 6.42773 15.3374 6.42773H8.52771C7.96709 6.42773 7.51098 6.88385 7.51098 7.44446V8.51163C7.00429 8.59774 6.61719 9.03959 6.61719 9.57038V11.6416C6.61719 12.1724 7.00429 12.6143 7.51098 12.7004V15.1511C7.00429 15.2373 6.61719 15.6791 6.61719 16.2099ZM8.40477 16.2099V18.2811C8.40477 18.5181 8.21202 18.7108 7.97509 18.7108H7.69141C7.45448 18.7108 7.26172 18.5181 7.26172 18.2811V16.2099C7.26172 15.973 7.45448 15.7802 7.69141 15.7802H7.97509C8.21202 15.7802 8.40477 15.973 8.40477 16.2099ZM7.26172 11.6416V9.57043C7.26172 9.3335 7.45448 9.14074 7.69141 9.14074H7.97509C8.21202 9.14074 8.40477 9.3335 8.40477 9.57043V11.6416C8.40477 11.8786 8.21202 12.0713 7.97509 12.0713H7.69141C7.45448 12.0713 7.26172 11.8785 7.26172 11.6416Z"
          fill="#3A3956"
        />
        <path
          d="M18.5174 17.4295C18.6524 17.5454 18.8559 17.53 18.9718 17.3949C19.7998 16.4306 20.2559 15.1985 20.2559 13.9258C20.2559 10.9878 17.8657 8.59766 14.9277 8.59766C11.9898 8.59766 9.59961 10.9878 9.59961 13.9258C9.59961 16.8637 11.9898 19.2539 14.9277 19.2539C15.9327 19.2539 16.9116 18.9726 17.7586 18.4404C17.9093 18.3457 17.9548 18.1467 17.86 17.996C17.7653 17.8454 17.5664 17.8 17.4157 17.8946C16.6716 18.3622 15.8113 18.6094 14.9277 18.6094C12.3452 18.6094 10.2441 16.5083 10.2441 13.9258C10.2441 11.3432 12.3452 9.24219 14.9277 9.24219C17.5103 9.24219 19.6113 11.3432 19.6113 13.9258C19.6113 15.0446 19.2106 16.1275 18.4828 16.9751C18.3669 17.1101 18.3823 17.3136 18.5174 17.4295Z"
          fill="#3A3956"
        />
        <path
          d="M18.2688 14.248C18.4468 14.248 18.5911 14.1037 18.5911 13.9257C18.5911 13.7477 18.4468 13.6034 18.2688 13.6034H17.0305C16.9776 13.2574 16.8408 12.9387 16.6419 12.6684L17.5184 11.7919C17.6443 11.666 17.6443 11.462 17.5184 11.3361C17.3926 11.2102 17.1886 11.2103 17.0627 11.3361L16.1861 12.2126C15.9158 12.0137 15.5972 11.8769 15.2511 11.8241V10.5857C15.2511 10.4077 15.1068 10.2634 14.9288 10.2634C14.7508 10.2634 14.6066 10.4077 14.6066 10.5857V11.8241C14.2605 11.8769 13.9418 12.0137 13.6715 12.2127L12.795 11.3361C12.6691 11.2103 12.465 11.2102 12.3392 11.3361C12.2134 11.462 12.2134 11.6661 12.3392 11.7919L13.2158 12.6684C13.0168 12.9387 12.88 13.2574 12.8272 13.6034H11.5889C11.4109 13.6034 11.2666 13.7477 11.2666 13.9257C11.2666 14.1037 11.4109 14.248 11.5889 14.248H12.8272C12.88 14.594 13.0168 14.9127 13.2158 15.183L12.3392 16.0595C12.2134 16.1854 12.2134 16.3894 12.3392 16.5153C12.4651 16.6412 12.6691 16.6411 12.795 16.5153L13.6715 15.6387C13.9418 15.8376 14.2605 15.9745 14.6066 16.0273V17.2657C14.6066 17.4437 14.7508 17.588 14.9288 17.588C15.1068 17.588 15.2511 17.4437 15.2511 17.2657V16.0273C15.5972 15.9745 15.9158 15.8377 16.1861 15.6388L17.0627 16.5153C17.1886 16.6411 17.3926 16.6412 17.5184 16.5153C17.6443 16.3894 17.6443 16.1853 17.5184 16.0595L16.6419 15.183C16.8408 14.9127 16.9776 14.594 17.0305 14.248H18.2688ZM14.9288 15.4072C14.1119 15.4072 13.4473 14.7426 13.4473 13.9257C13.4473 13.1088 14.1119 12.4442 14.9288 12.4442C15.7457 12.4442 16.4103 13.1088 16.4103 13.9257C16.4103 14.7426 15.7457 15.4072 14.9288 15.4072Z"
          fill="#3A3956"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="22"
            height="22"
            fill="white"
            transform="translate(4 4)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
export { IconSafe }
