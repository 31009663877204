/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { useTheme } from "emotion-theming"
import GoogleMapReact from "google-map-react"
import { useEffect, useState } from "react"

import { distanceData } from "config/data"

const Dot = ({ color, children }) => {
  const theme = useTheme()
  const SIZE = 24
  const styles = css`
    .dot {
      width: ${SIZE}px;
      height: ${SIZE}px;
      border-radius: ${SIZE}px;
      background-color: ${color || theme.color.red};
      display: block;
    }
    .label {
      background: ${color || theme.color.red};
      color: white;
      padding: 4px;
      border-radius: 4px;
      position: absolute;
      font-size: 12px;
    }
  `

  return (
    <div css={styles}>
      <div className="dot" />
    </div>
  )
}

const Map = ({ index }) => {
  const theme = useTheme()
  const styles = css`
    height: 420px;
    width: 100vw;
  `

  const [loaded, setLoaded] = useState(null)

  useEffect(() => {
    setLoaded(true)
  }, [])

  return loaded ? (
    <div css={styles}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyALOlvmmwdjDO5PuC7heOBD78d-8DOVvWA" }}
        defaultCenter={distanceData.steelCity.center}
        defaultZoom={16}
      >
        {distanceData.locations.map(location => {
          return (
            <Dot
              key={location.name}
              {...location.center}
              color={theme.color.darkpurple}
            >
              {location.name}
            </Dot>
          )
        })}
        <Dot {...distanceData.steelCity.center}>Steel City</Dot>
        {/* <Dot
          {...distanceData.locations[index].center}
          color={theme.color.darkpurple}
        /> */}
      </GoogleMapReact>
    </div>
  ) : (
    ""
  )
}
export { Map }
