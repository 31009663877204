/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { useTheme } from "emotion-theming"
import { useState, useEffect } from "react"
import { useIntl } from "gatsby-plugin-intl"
import { TenantCard } from "components"

const TenantSidebar = ({ data }) => {
  const theme = useTheme()
  const styles = css`
    position: relative;
    padding: ${theme.padding[200]};

    &[data-loaded="false"] {
      .TenantCard {
        opacity: 0;
      }
    }

    &[data-loaded="true"] {
      .TenantCard {
        opacity: 1;

        &:nth-of-type(1) {
          transition: opacity 0.2s ease;
        }
        &:nth-of-type(2) {
          transition: opacity 0.2s ease 0.1s;
        }
        &:nth-of-type(3) {
          transition: opacity 0.2s ease 0.2s;
        }
      }
    }

    h3 {
      font-size: 24px;
      margin-bottom: 0;
      border-bottom: 1px solid #89899d;
      padding-bottom: ${theme.padding[200]};
    }
  `

  const intl = useIntl()
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setLoaded(false)
    setTimeout(() => setLoaded(true), 200)
  }, [data])

  return (
    <div css={styles} data-loaded={loaded}>
      <h3>{intl.formatMessage({ id: "rooms.misc.tenancyOptions" })}</h3>
      {data.tenancies.map((tenancy, i) => (
        <TenantCard key={`tenancy-${i}`} {...tenancy} />
      ))}
    </div>
  )
}
export { TenantSidebar }
