import React from "react"

import * as IMG from "./images"

import {
  IconFitness,
  IconSafe,
  IconShoe,
  IconSnowflake,
  IconWifi,
  IconCommunal,
  IconInsurance,
  IconLocation,
  IconManagement,
  IconSecure,
  IconTV,
  IconPricing,
} from "components/Icons/Benefits"

import { gallery } from "./gallery"
import ImgUSheff from "images/locations/usheff.jpg"
import ImgHallam from "images/locations/hallam.jpg"
import ImgCollege from "images/locations/college.jpg"
import ImgDiamond from "images/locations/diamond.jpg"
import ImgInternational from "images/locations/international.jpg"

import Vid1 from "video/01.mp4"
import Vid2 from "video/02.mp4"
import Vid3 from "video/03.mp4"
import Vid4 from "video/04.mp4"

import vidPoster1 from "video/vid1.jpg"
import vidPoster2 from "video/vid2.jpg"
import vidPoster3 from "video/vid3.jpg"
import vidPoster4 from "video/vid4.jpg"

// === VIDEO DATA === //
export const videoData = [
  {
    video: {
      poster: vidPoster1,
      src: Vid1,
    },
  },
  {
    video: {
      poster: vidPoster4,
      src: Vid4,
    },
  },
  {
    video: {
      poster: vidPoster2,
      src: Vid2,
    },
  },
  {
    video: {
      poster: vidPoster3,
      src: Vid3,
    },
  },
]

// === BENEFITS === //
export const benefitsData = [
  {
    icon: <IconWifi />,
    title: "benefits.carousel.wifi.title",
    description: "benefits.carousel.wifi.description",
  },
  {
    icon: <IconShoe />,
    title: "benefits.carousel.walk.title",
    description: "benefits.carousel.walk.description",
  },
  {
    icon: <IconFitness />,
    title: "benefits.carousel.fitness.title",
    description: "benefits.carousel.fitness.description",
  },
  {
    icon: <IconLocation />,
    title: "benefits.carousel.location.title",
    description: "benefits.carousel.location.description",
  },
  {
    icon: <IconInsurance />,
    title: "benefits.carousel.insurance.title",
    description: "benefits.carousel.insurance.description",
  },
  {
    icon: <IconSnowflake />,
    title: "benefits.carousel.ac.title",
    description: "benefits.carousel.ac.description",
  },
  {
    icon: <IconSafe />,
    title: "benefits.carousel.safe.title",
    description: "benefits.carousel.safe.description",
  },
  {
    icon: <IconSecure />,
    title: "benefits.carousel.secure.title",
    description: "benefits.carousel.secure.description",
  },
  {
    icon: <IconPricing />,
    title: "benefits.carousel.pricing.title",
    description: "benefits.carousel.pricing.description",
  },
  {
    icon: <IconCommunal />,
    title: "benefits.carousel.communal.title",
    description: "benefits.carousel.communal.description",
  },
  {
    icon: <IconTV />,
    title: "benefits.carousel.tv.title",
    description: "benefits.carousel.tv.description",
  },
  {
    icon: <IconManagement />,
    title: "benefits.carousel.management.title",
    description: "benefits.carousel.management.description",
  },
]

// === ROOMS === //

// == CONSTANTS == //
const AIR_CONDITIONING = "rooms.benefits.ac"
const COMMON_ROOM = "rooms.benefits.common"
const GYM = "rooms.benefits.gym"
const ROOF_TERRACE = "rooms.benefits.terrace"
const WIFI = "rooms.benefits.wifi"
const COOKER = "rooms.benefits.cooker"
const FREEZER = "rooms.benefits.freezer"
const MICROWAVE_OVEN = "rooms.benefits.microwave"
const DESK_CHAIR = "rooms.benefits.desk"
const TV_IN_ROOM = "rooms.benefits.tv"
const SECURITY_24 = "rooms.benefits.security"
const MAINTENANCE = "rooms.benefits.maintenance"

// == DATA == //
export const roomData = [
  {
    name: "rooms.details.ths.name",
    price: 99,
    description: "rooms.details.ths.description",
    features: [
      AIR_CONDITIONING,
      COMMON_ROOM,
      GYM,
      ROOF_TERRACE,
      WIFI,
      COOKER,
      FREEZER,
      MICROWAVE_OVEN,
      DESK_CHAIR,
      TV_IN_ROOM,
      SECURITY_24,
      MAINTENANCE,
    ],
    images: [...IMG.THS],
    tenancies: [
      {
        duration: 51,
        price: 99,
        start: "19/09/2020",
      },
    ],
  },
  {
    name: "rooms.details.ce.name",
    price: 125,
    description: "rooms.details.ce.description",
    features: [
      AIR_CONDITIONING,
      GYM,
      ROOF_TERRACE,
      WIFI,
      COOKER,
      FREEZER,
      MICROWAVE_OVEN,
      DESK_CHAIR,
      TV_IN_ROOM,
      SECURITY_24,
      MAINTENANCE,
    ],
    images: [...IMG.CE],
    tenancies: [
      {
        duration: 51,
        price: 125,
        start: "19/09/2020",
      },
      {
        duration: 47,
        price: 129,
        start: "17/10/2020",
      },
      {
        duration: 44,
        price: 135,
        start: "19/09/2020",
      },
    ],
  },
  {
    name: "rooms.details.pe.name",
    price: 135,
    description: "rooms.details.pe.description",
    features: [
      AIR_CONDITIONING,
      COMMON_ROOM,
      GYM,
      ROOF_TERRACE,
      WIFI,
      COOKER,
      FREEZER,
      MICROWAVE_OVEN,
      DESK_CHAIR,
      TV_IN_ROOM,
      SECURITY_24,
      MAINTENANCE,
    ],
    images: [...IMG.PE],
    tenancies: [
      {
        duration: 51,
        price: 135,
        start: "19/09/2020",
      },
      {
        duration: 47,
        price: 142,
        start: "17/10/2020",
      },
      {
        duration: 44,
        price: 149,
        start: "19/09/2020",
      },
    ],
  },
  {
    name: "rooms.details.cs.name",
    price: 159,
    description: "rooms.details.cs.description",
    features: [
      AIR_CONDITIONING,
      COMMON_ROOM,
      GYM,
      ROOF_TERRACE,
      WIFI,
      COOKER,
      FREEZER,
      MICROWAVE_OVEN,
      DESK_CHAIR,
      TV_IN_ROOM,
      SECURITY_24,
      MAINTENANCE,
    ],
    images: [...IMG.CS],
    tenancies: [
      {
        duration: 51,
        price: 159,
        start: "19/09/2020",
      },
      {
        duration: 47,
        price: 162,
        start: "17/10/2020",
      },
      {
        duration: 44,
        price: 169,
        start: "19/09/2020",
      },
    ],
  },
  {
    name: "rooms.details.thce.name",
    price: null,
    description: "rooms.details.tche.description",
    features: [
      AIR_CONDITIONING,
      COMMON_ROOM,
      GYM,
      ROOF_TERRACE,
      WIFI,
      COOKER,
      FREEZER,
      MICROWAVE_OVEN,
      DESK_CHAIR,
      TV_IN_ROOM,
      SECURITY_24,
      MAINTENANCE,
    ],
    images: [...IMG.THCE],
    tenancies: [
      {
        duration: 51,
        price: null,
        start: "19/09/2020",
      },
    ],
  },
  {
    name: "rooms.details.thpe.name",
    price: null,
    description: "rooms.details.thce.name",
    features: [
      AIR_CONDITIONING,
      COMMON_ROOM,
      GYM,
      ROOF_TERRACE,
      WIFI,
      COOKER,
      FREEZER,
      MICROWAVE_OVEN,
      DESK_CHAIR,
      TV_IN_ROOM,
      SECURITY_24,
      MAINTENANCE,
    ],
    images: [...IMG.THPE],
    tenancies: [
      {
        duration: 51,
        price: null,
        start: "19/09/2020",
      },
    ],
  },
  {
    name: "rooms.details.pas.name",
    price: 169,
    description: "rooms.details.pas.description",
    features: [
      AIR_CONDITIONING,
      COMMON_ROOM,
      GYM,
      ROOF_TERRACE,
      WIFI,
      COOKER,
      FREEZER,
      MICROWAVE_OVEN,
      DESK_CHAIR,
      TV_IN_ROOM,
      SECURITY_24,
    ],
    images: [...IMG.PAS],
    tenancies: [
      {
        duration: 51,
        price: 169,
        start: "19/09/2020",
      },
    ],
  },
  {
    name: "rooms.details.tba.name",
    price: 174,
    description: "rooms.details.tba.description",
    features: [
      AIR_CONDITIONING,
      COMMON_ROOM,
      GYM,
      ROOF_TERRACE,
      WIFI,
      COOKER,
      FREEZER,
      MICROWAVE_OVEN,
      DESK_CHAIR,
      TV_IN_ROOM,
      SECURITY_24,
      MAINTENANCE,
    ],
    images: [...IMG.TBA],
    tenancies: [
      {
        duration: 51,
        price: 174,
        start: "19/09/2020",
      },
    ],
  },
  {
    name: "rooms.details.ps.name",
    price: 175,
    description: "rooms.details.ps.description",
    features: [
      AIR_CONDITIONING,
      COMMON_ROOM,
      GYM,
      ROOF_TERRACE,
      WIFI,
      COOKER,
      FREEZER,
      MICROWAVE_OVEN,
      DESK_CHAIR,
      TV_IN_ROOM,
      SECURITY_24,
      MAINTENANCE,
    ],
    images: [...IMG.PS],
    tenancies: [
      {
        duration: 51,
        price: 175,
        start: "19/09/2020",
      },
      {
        duration: 47,
        price: 180,
        start: "19/09/2020",
      },
      {
        duration: 44,
        price: 185,
        start: "19/09/2020",
      },
    ],
  },
  {
    name: "rooms.details.ls.name",
    price: 215,
    description: "rooms.details.ls.description",
    features: [
      AIR_CONDITIONING,
      COMMON_ROOM,
      GYM,
      ROOF_TERRACE,
      WIFI,
      COOKER,
      FREEZER,
      MICROWAVE_OVEN,
      DESK_CHAIR,
      TV_IN_ROOM,
      SECURITY_24,
      MAINTENANCE,
    ],
    images: [...IMG.LS],
    tenancies: [
      {
        duration: 51,
        price: 215,
        start: "19/09/2020",
      },
    ],
  },
  {
    name: "rooms.details.oba.name",
    price: 239,
    description: "rooms.details.oba.description",
    features: [
      AIR_CONDITIONING,
      COMMON_ROOM,
      GYM,
      ROOF_TERRACE,
      WIFI,
      COOKER,
      FREEZER,
      MICROWAVE_OVEN,
      DESK_CHAIR,
      TV_IN_ROOM,
      SECURITY_24,
    ],
    images: [...IMG.OBA],
    tenancies: [
      {
        duration: 51,
        price: 239,
        start: "19/09/2020",
      },
    ],
  },
  {
    name: "rooms.details.da.name",
    price: 249,
    description: "rooms.details.da.description",
    features: [
      AIR_CONDITIONING,
      COMMON_ROOM,
      GYM,
      ROOF_TERRACE,
      WIFI,
      COOKER,
      FREEZER,
      MICROWAVE_OVEN,
      DESK_CHAIR,
      TV_IN_ROOM,
      SECURITY_24,
      MAINTENANCE,
    ],
    images: [...IMG.DA],
    tenancies: [
      {
        duration: 51,
        price: 249,
        start: "19/09/2020",
      },
    ],
  },
  {
    name: "rooms.details.phs.name",
    price: 255,
    description: "rooms.details.phs.description",
    features: [
      AIR_CONDITIONING,
      COMMON_ROOM,
      GYM,
      ROOF_TERRACE,
      WIFI,
      COOKER,
      FREEZER,
      MICROWAVE_OVEN,
      DESK_CHAIR,
      TV_IN_ROOM,
      SECURITY_24,
      MAINTENANCE,
    ],
    images: [...IMG.PHS],
    tenancies: [
      {
        duration: 51,
        price: 255,
        start: "19/09/2020",
      },
    ],
  },
]

// === GALLERY DATA === //

export const galleryData = [...gallery]

// === DISTANCE DATA === //
export const distanceData = {
  steelCity: {
    center: {
      lat: 53.38369,
      lng: -1.47672,
    },
  },
  locations: [
    {
      name: "distance.uni.usheff",
      image: ImgUSheff,
      walk: 13,
      cycle: 6,
      center: {
        lat: 53.375031,
        lng: -1.48572,
      },
    },
    {
      name: "distance.uni.hallam",
      image: ImgHallam,
      walk: 15,
      cycle: 6,
      center: {
        lat: 53.379212,
        lng: -1.465255,
      },
    },
    {
      name: "distance.uni.college",
      image: ImgCollege,
      walk: 25,
      cycle: 11,
      center: {
        lat: 53.372537,
        lng: -1.459972,
      },
    },
    {
      name: `distance.uni.diamond`,
      image: ImgDiamond,
      walk: 7,
      cycle: 3,
      center: {
        lat: 53.381638,
        lng: -1.482064,
      },
    },
    {
      name: "distance.uni.international",
      image: ImgInternational,
      walk: 8,
      cycle: 4,
      center: {
        lat: 53.384686,
        lng: -1.47344,
      },
    },
  ],
}
