/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { useTheme } from "emotion-theming"
import { useIntl } from "gatsby-plugin-intl"
import { useModal } from "context"

import { Form, Confirmation } from "components"

import Image from "./modal.jpg"

const Modal = ({ active }) => {
  const theme = useTheme()
  const styles = css`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    display: ${active ? "flex" : "none"};
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 480px) {
      position: absolute;
      display: block;
      height: auto;
    }

    .bg {
      width: 100vw;
      height: 100vh;
      position: fixed;
      background: rgba(0, 0, 0, 0.9);
      backdrop-filter: blur(16px);
      top: 0;
      left: 0;
    }

    .m {
      position: relative;
      padding: ${theme.padding[300]};

      @media screen and (max-width: 1000px) {
        width: 100%;
      }

      @media screen and (max-width: 380px) {
        padding: ${theme.padding[200]};
      }

      .close {
        text-align: right;
        color: white;
        margin-bottom: ${theme.margin[100]};
        cursor: pointer;
      }

      .content {
        background: white;
        width: 950px;
        max-width: 950px;
        display: flex;

        @media screen and (max-width: 1000px) {
          width: 100%;
        }

        @media screen and (max-width: 790px) {
          display: block;
        }

        .l {
          min-width: 370px;
          background: #121212;

          @media screen and (max-width: 1000px) {
            min-width: 300px;
            width: 300px;
          }

          @media screen and (max-width: 850px) {
            min-width: 240px;
            width: 240px;
          }

          @media screen and (max-width: 790px) {
            min-width: 100%;
            width: 100%;
            height: 160px;
          }

          @media screen and (max-width: 480px) {
            display: none;
          }

          img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
          }
        }

        .r {
          width: 580px;
          padding: ${theme.padding[300]};
          ${theme.color.darkpurple};

          @media screen and (max-width: 790px) {
            width: 100%;
          }

          h4 {
            font-size: 24px;
            font-family: "Product Sans", sans-serif;
          }

          p {
            margin-bottom: ${theme.margin[300]};
            font-size: 15px;
            line-height: 1.3;
            color: ${theme.color.lightpurple};
          }
        }
      }
    }
  `
  const intl = useIntl()
  const { submitted } = useModal()
  const { setActive } = useModal().setState

  return (
    <div css={styles}>
      <div className="bg" onClick={() => setActive(false)} />
      <div className="m">
        <div className="close" onClick={() => setActive(false)}>
          {intl.formatMessage({ id: "form.close" })} x
        </div>
        <div className="content">
          <div className="l">
            <img src={Image} alt="" />
          </div>
          <div className="r">{!submitted ? <Form /> : <Confirmation />}</div>
        </div>
      </div>
    </div>
  )
}
export { Modal }
