/** @jsx jsx */
import { jsx } from "@emotion/core"
const IconWifi = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M21.6973 16.2911C21.4791 16.2911 21.267 16.1766 21.1521 15.9732C19.8995 13.7515 17.5423 12.3715 15.0002 12.3715C12.4581 12.3715 10.1009 13.7515 8.84819 15.9732C8.67881 16.2742 8.29734 16.3796 7.99674 16.2106C7.69615 16.0409 7.58994 15.6597 7.75932 15.3591C9.23332 12.7453 12.008 11.1215 15.0002 11.1215C17.9925 11.1215 20.7671 12.7453 22.2411 15.3591C22.4105 15.6597 22.3043 16.0409 22.0037 16.2106C21.9065 16.2652 21.8014 16.2911 21.6973 16.2911Z"
          fill="#3A3956"
        />
        <path
          d="M19.018 17.798C18.7998 17.798 18.5877 17.6836 18.473 17.4802C17.7659 16.2254 16.4347 15.4462 14.9994 15.4462C13.5642 15.4462 12.233 16.2256 11.5259 17.4802C11.3565 17.7808 10.9757 17.8873 10.6745 17.7176C10.3739 17.5482 10.2677 17.1671 10.4371 16.8661C11.3654 15.2194 13.1138 14.1962 14.9994 14.1962C16.885 14.1962 18.6335 15.2194 19.5617 16.8661C19.731 17.1671 19.625 17.5482 19.3242 17.7176C19.2272 17.7721 19.1218 17.798 19.018 17.798Z"
          fill="#3A3956"
        />
        <path
          d="M24.3757 14.7846C24.1574 14.7846 23.9452 14.6701 23.8305 14.4666C22.0325 11.2778 18.649 9.29688 15 9.29688C11.3511 9.29688 7.96757 11.2778 6.16947 14.4666C6.00041 14.7672 5.61894 14.8734 5.31803 14.704C5.01744 14.5343 4.91123 14.1531 5.08061 13.8525C7.09995 10.2716 10.9006 8.04688 15 8.04688C19.0995 8.04688 22.9 10.2716 24.9195 13.8525C25.0888 14.1531 24.9826 14.5343 24.682 14.7038C24.585 14.7586 24.4797 14.7846 24.3757 14.7846Z"
          fill="#3A3956"
        />
        <path
          d="M16.875 20.0913C16.875 21.1268 16.0355 21.9663 15 21.9663C13.9644 21.9663 13.125 21.1268 13.125 20.0913C13.125 19.0557 13.9644 18.2163 15 18.2163C16.0355 18.2163 16.875 19.0557 16.875 20.0913Z"
          fill="#3A3956"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(5 5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
export { IconWifi }
