/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { useTheme } from "emotion-theming"
import { useIntl } from "gatsby-plugin-intl"
import { useModal } from "context"

import { Wrapper } from "components"
import Logo from "./logo-foot.svg"

const Footer = () => {
  const theme = useTheme()
  const styles = css`
    background: ${theme.color.darkpurple};
    color: white;
    padding: 0 ${theme.padding[300]};

    @media screen and (max-width: 380px) {
      padding: ${theme.padding[200]} ${theme.padding[200]} 0;
    }

    .W {
      margin: auto;
      padding-bottom: ${theme.margin[400]};
      display: flex;
      font-size: 16px;
      align-items: center;

      @media screen and (max-width: 880px) {
        display: block;
      }

      .Logo {
        width: 168px;

        @media screen and (max-width: 1140px) {
          width: 120px;
        }

        @media screen and (max-width: 880px) {
          margin: 0 auto;
        }

        @media screen and (max-width: 580px) {
          margin: 0;
        }

        img {
          width: 100%;
          display: block;
        }
      }

      nav {
        margin-left: ${theme.margin[400]};

        @media screen and (max-width: 1140px) {
          margin-left: ${theme.margin[100]};
        }

        @media screen and (max-width: 880px) {
          margin: ${theme.margin[200]} auto;
        }

        ul {
          display: flex;
          padding: ${theme.padding[100]} 0;
          font-size: 14px;

          @media screen and (max-width: 880px) {
            justify-content: center;
          }

          @media screen and (max-width: 580px) {
            display: block;
            padding: 0;
          }

          @media screen and (max-width: 380px) {
            padding-bottom: ${theme.padding[200]};
          }

          li {
            font-size: 14px;
            padding: ${theme.padding[100]} ${theme.padding[200]};
            color: ${theme.color.lightpurple};

            &:hover {
              text-decoration: underline;
              color: white;
              cursor: pointer;
            }

            a {
              color: inherit;
              text-decoration: none;
            }

            &:not(:last-of-type) {
              border-right: 1px solid rgba(255, 255, 255, 0.1);

              @media screen and (max-width: 580px) {
                margin-bottom: ${theme.margin[200]};
                border-right: none;
              }
            }

            @media screen and (max-width: 580px) {
              padding: 0;
            }
          }
        }
      }

      .cta {
        margin-left: auto;
        display: flex;

        @media screen and (max-width: 1080px) {
          display: block;
        }

        @media screen and (max-width: 880px) {
          display: flex;
          justify-content: center;
        }

        @media screen and (max-width: 580px) {
          justify-content: flex-start;
        }

        @media screen and (max-width: 340px) {
          display: block;
        }

        li {
          padding: 12px;
          width: 160px;
          text-align: center;

          @media screen and (max-width: 340px) {
            width: auto;
          }

          &:first-of-type {
            background: ${theme.color.darkpurple};
            border: 1px solid ${theme.color.lightpurple};
            margin-right: ${theme.margin[100]};

            @media screen and (max-width: 1080px) {
              margin-right: 0;
              margin-bottom: ${theme.margin[100]};
            }

            @media screen and (max-width: 880px) {
              margin-right: ${theme.margin[100]};
              margin-bottom: 0;
            }

            @media screen and (max-width: 340px) {
              margin-right: 0;
              margin-bottom: ${theme.margin[100]};
            }
          }

          &:last-of-type {
            background: ${theme.color.red};
            border: 1px solid ${theme.color.red};
          }
        }
      }
    }
  `
  const intl = useIntl()

  const { setActive } = useModal().setState

  return (
    <footer css={styles}>
      <Wrapper>
        <div className="Logo">
          <img src={Logo} alt="" />
        </div>
        <nav>
          <ul>
            <li>
              <a href="#welcome">{intl.formatMessage({ id: "header.why" })}</a>
            </li>
            <li>
              <a href="#rooms">{intl.formatMessage({ id: "header.rooms" })}</a>
            </li>
            <li>
              <a href="#explore">
                {intl.formatMessage({ id: "header.explore" })}
              </a>
            </li>
            <li onClick={() => setActive(true)}>
              {intl.formatMessage({ id: "header.question" })}
            </li>
            <li onClick={() => setActive(true)}>
              {intl.formatMessage({ id: "header.contact" })}
            </li>
          </ul>
        </nav>
        <ul className="cta">
          <li onClick={() => setActive(true)}>
            {intl.formatMessage({ id: "header.viewing" })}
          </li>
          <li onClick={() => setActive(true)}>
            {intl.formatMessage({ id: "header.book" })}
          </li>
        </ul>
      </Wrapper>
    </footer>
  )
}
export { Footer }
