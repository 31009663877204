/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { useTheme } from "emotion-theming"
import { useIntl } from "gatsby-plugin-intl"
import { Formik, Form as FM } from "formik"
import * as Yup from "yup"
import { useModal } from "context"
import { useLocation } from "@reach/router"
import queryString from "query-string"

import { Input, Textarea } from "components"

const INITIAL_STATE = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  message: "",
}

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const FormSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too short!")
    .max(50, "Too long")
    .required("Required"),
  lastName: Yup.string()
    .min(2, "Too short!")
    .max(50, "Too long")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  phone: Yup.string()
    .min(2, "Too short!")
    .max(50, "Too long")
    .required("Required"),
  message: Yup.string(),
})

const Form = () => {
  const theme = useTheme()
  const styles = css`
    .grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: ${theme.margin[300]};
      row-gap: ${theme.margin[300]};
      margin: ${theme.margin[300]} 0;

      @media screen and (max-width: 640px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    .buttons {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-top: ${theme.margin[300]};

      @media screen and (max-width: 540px) {
        display: block;
      }

      button {
        appearance: none;
        font: inherit;
        border: none;
        color: white;
        width: 220px;
        padding: 12px;
        border-radius: 4px;
        font-size: 16px;
        cursor: pointer;

        @media screen and (max-width: 540px) {
          width: 100%;
        }

        &:first-of-type {
          background: transparent;
          border: 1px solid ${theme.color.lightpurple};
          color: inherit;

          @media screen and (max-width: 540px) {
            margin-bottom: ${theme.margin[100]};
          }
        }

        &:last-of-type {
          background: ${theme.color.red};
          border: 1px solid ${theme.color.red};
        }
      }
    }
  `
  const intl = useIntl()
  const { setSubmitted, setActive } = useModal().setState
  const location = useLocation()

  const getUTM = () => {
    const fallback = {
      utm_source: "none",
      utm_medium: "none",
    }

    if (location.search) {
      const query = queryString.parse(location.search)
      const { utm_source, utm_medium } = query

      if (utm_source && utm_medium) {
        return {
          utm_source,
          utm_medium,
        }
      }
    }

    return fallback
  }

  const handleReset = e => {
    e.preventDefault()
    setActive(false)
  }

  const { utm_source, utm_medium } = getUTM()

  return (
    <Formik
      initialValues={{ ...INITIAL_STATE }}
      onSubmit={(values, actions) => {
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({
            "form-name": "steel-city",
            ...values,
            utm_source,
            utm_medium,
          }),
        })
          .then(() => {
            actions.resetForm()
          })
          .catch(() => {
            console.error("Error")
          })
          .finally(() => {
            actions.setSubmitting(false)
            setSubmitted(true)
          })
      }}
      validationSchema={FormSchema}
    >
      {({ values, handleChange }) => (
        <FM name="steel-city" data-netlify={true} css={styles}>
          <h4>{intl.formatMessage({ id: "form.title" })}</h4>
          <p>{intl.formatMessage({ id: "form.description" })}</p>
          <div className="grid">
            <Input
              id="firstName"
              onChange={handleChange}
              value={values.firstName}
              placeholder={intl.formatMessage({
                id: "form.fields.firstName.placeholder",
              })}
              required
            >
              {intl.formatMessage({ id: "form.fields.firstName.label" })}
            </Input>
            <Input
              id="lastName"
              onChange={handleChange}
              value={values.lastName}
              placeholder={intl.formatMessage({
                id: "form.fields.lastName.placeholder",
              })}
              required
            >
              {intl.formatMessage({ id: "form.fields.lastName.label" })}
            </Input>
            <Input
              id="email"
              onChange={handleChange}
              value={values.email}
              placeholder={intl.formatMessage({
                id: "form.fields.email.placeholder",
              })}
              required
            >
              {intl.formatMessage({ id: "form.fields.email.label" })}
            </Input>
            <Input
              id="phone"
              onChange={handleChange}
              value={values.phone}
              placeholder={intl.formatMessage({
                id: "form.fields.phone.placeholder",
              })}
              required
            >
              {intl.formatMessage({ id: "form.fields.phone.label" })}
            </Input>
          </div>
          <Textarea
            id="message"
            onChange={handleChange}
            value={values.message}
            placeholder={intl.formatMessage({
              id: "form.fields.message.placeholder",
            })}
          >
            {intl.formatMessage({ id: "form.fields.message.label" })}
          </Textarea>
          <div className="buttons">
            <button onClick={handleReset} type="reset">
              {intl.formatMessage({ id: "form.cancel" })}
            </button>
            <button type="submit">
              {intl.formatMessage({ id: "form.send" })}
            </button>
          </div>
          <input type="hidden" name="utm_source" value={utm_source} />
          <input type="hidden" name="utm_medium" value={utm_medium} />
        </FM>
      )}
    </Formik>
  )
}
export { Form }
