/// === IMAGES === //

// == Classic Ensuite (CE) == //
import CEFloor from "images/rooms/ce0.jpg"
import CE1 from "images/rooms/ce1.jpg"
import CE2 from "images/rooms/ce2.jpg"
import CE3 from "images/rooms/ce3.jpg"

// == Classic Studio (CS) == //
import CSFloor from "images/rooms/cs0.jpg"
import CS1 from "images/rooms/cs1.jpg"
import CS2 from "images/rooms/cs2.jpg"
import CS3 from "images/rooms/cs3.jpg"
import CS4 from "images/rooms/cs4.jpg"
import CS5 from "images/rooms/cs5.jpg"
import CS6 from "images/rooms/cs6.jpg"

// == Duplex Apartment (DA) == //
import DAFloor from "images/rooms/da0.jpg"
import DA1 from "images/rooms/da1.jpg"
import DA2 from "images/rooms/da2.jpg"
import DA3 from "images/rooms/da3.jpg"
import DA4 from "images/rooms/da4.jpg"
import DA5 from "images/rooms/da5.jpg"
import DA6 from "images/rooms/da6.jpg"
import DA7 from "images/rooms/da7.jpg"
import DA8 from "images/rooms/da8.jpg"

// == Luxury Studio (LS) == //
import LSFloor from "images/rooms/ls0.jpg"
import LS1 from "images/rooms/ls1.jpg"
import LS2 from "images/rooms/ls2.jpg"
import LS3 from "images/rooms/ls3.jpg"
import LS4 from "images/rooms/ls4.jpg"
import LS5 from "images/rooms/ls5.jpg"
import LS6 from "images/rooms/ls6.jpg"
import LS7 from "images/rooms/ls7.jpg"

// == One Bed Aparment (OBA) == //
import OBAFloor from "images/rooms/oba0.jpg"
import OBA1 from "images/rooms/oba1.jpg"
import OBA2 from "images/rooms/oba2.jpg"
import OBA3 from "images/rooms/oba3.jpg"
import OBA4 from "images/rooms/oba4.jpg"

// == Premium Accessible Studio (PAS) == //
import PASFloor from "images/rooms/pas0.jpg"
import PAS1 from "images/rooms/pas1.jpg"
import PAS2 from "images/rooms/pas2.jpg"
import PAS3 from "images/rooms/pas3.jpg"

// == Premium Ensuite (PE) == //
import PEFloor from "images/rooms/pe0.jpg"
import PE1 from "images/rooms/pe1.jpg"
import PE2 from "images/rooms/pe2.jpg"
import PE3 from "images/rooms/pe3.jpg"
import PE4 from "images/rooms/pe4.jpg"
import PE5 from "images/rooms/pe5.jpg"
import PE6 from "images/rooms/pe6.jpg"
import PE7 from "images/rooms/pe7.jpg"

// == Penthouse Studio (PHS) == //
import PHSFloor from "images/rooms/phs0.jpg"
import PHS1 from "images/rooms/phs1.jpg"
import PHS2 from "images/rooms/phs2.jpg"
import PHS3 from "images/rooms/phs3.jpg"
import PHS4 from "images/rooms/phs4.jpg"
import PHS5 from "images/rooms/phs5.jpg"

// == Premium Studio (PS) == //
import PSFloor from "images/rooms/ps0.jpg"
import PS1 from "images/rooms/ps1.jpg"
import PS2 from "images/rooms/ps2.jpg"
import PS3 from "images/rooms/ps3.jpg"
import PS4 from "images/rooms/ps4.jpg"
import PS5 from "images/rooms/ps5.jpg"
import PS6 from "images/rooms/ps6.jpg"
import PS7 from "images/rooms/ps7.jpg"
import PS8 from "images/rooms/ps8.jpg"

// == Two Bedroom Apartment (TBA) == //
import TBAFloor from "images/rooms/tba0.jpg"
import TBA1 from "images/rooms/tba1.jpg"
import TBA2 from "images/rooms/tba2.jpg"
import TBA3 from "images/rooms/tba3.jpg"
import TBA4 from "images/rooms/tba4.jpg"

// == Townhouse Classic Ensuite == //
import THCEFloor from "images/rooms/thce0.jpg"
import THCE1 from "images/rooms/thce1.jpg"
import THCE2 from "images/rooms/thce2.jpg"
import THCE3 from "images/rooms/thce3.jpg"
import THCE4 from "images/rooms/thce4.png"

// == Townhouse Premium Ensuite == //
import THPEFloor from "images/rooms/thpe0.jpg"
import THPE1 from "images/rooms/thpe1.jpg"
import THPE2 from "images/rooms/thpe2.jpg"
import THPE3 from "images/rooms/thpe3.jpg"
import THPE4 from "images/rooms/thpe4.jpg"

// == Townhouse Standard == //
import THSFloor from "images/rooms/ths0.jpg"
import THS1 from "images/rooms/ths1.jpg"
import THS2 from "images/rooms/ths2.jpg"
import THS3 from "images/rooms/ths3.jpg"

export const CE = [CEFloor, CE1, CE2, CE3]
export const CS = [CSFloor, CS1, CS2, CS3, CS4, CS5, CS6]
export const DA = [DAFloor, DA1, DA2, DA3, DA4, DA5, DA6, DA7, DA8]
export const LS = [LSFloor, LS1, LS2, LS3, LS4, LS5, LS6, LS7]
export const OBA = [OBAFloor, OBA1, OBA2, OBA3, OBA4]
export const PAS = [PASFloor, PAS1, PAS2, PAS3]
export const PE = [PEFloor, PE1, PE2, PE3, PE4, PE5, PE6, PE7]
export const PHS = [PHSFloor, PHS1, PHS2, PHS3, PHS4, PHS5]
export const PS = [PSFloor, PS1, PS2, PS3, PS4, PS5, PS6, PS7, PS8]
export const TBA = [TBAFloor, TBA1, TBA2, TBA3, TBA4]
export const THCE = [THCEFloor, THCE1, THCE2, THCE3, THCE4]
export const THPE = [THPEFloor, THPE1, THPE2, THPE3, THPE4]
export const THS = [THSFloor, THS1, THS2, THS3]
