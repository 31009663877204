// === Gallery Images === //
import G01 from "images/gallery/01.jpeg"
import G02 from "images/gallery/02.jpeg"
import G03 from "images/gallery/03.jpeg"
import G04 from "images/gallery/04.jpg"
import G05 from "images/gallery/05.jpeg"
import G06 from "images/gallery/06.jpeg"
import G07 from "images/gallery/07.jpeg"
import G08 from "images/gallery/08.jpeg"
import G09 from "images/gallery/09.png"
import G10 from "images/gallery/10.jpeg"
import G11 from "images/gallery/11.jpeg"
import G12 from "images/gallery/12.jpeg"
import G13 from "images/gallery/13.jpeg"
import G14 from "images/gallery/14.jpeg"
import G15 from "images/gallery/15.jpeg"
import G16 from "images/gallery/16.jpeg"
import G17 from "images/gallery/17.jpeg"
import G18 from "images/gallery/18.jpeg"
import G19 from "images/gallery/19.jpeg"
import G20 from "images/gallery/20.jpeg"
import G21 from "images/gallery/21.jpeg"
import G22 from "images/gallery/22.jpeg"
import G23 from "images/gallery/23.jpeg"
import G24 from "images/gallery/24.jpeg"
import G25 from "images/gallery/25.jpeg"
import G26 from "images/gallery/26.jpeg"
import G27 from "images/gallery/27.jpeg"
import G28 from "images/gallery/28.jpeg"

export const gallery = [
  G01,
  G02,
  G03,
  G04,
  G05,
  G06,
  G07,
  G08,
  G09,
  G10,
  G11,
  G12,
  G13,
  G14,
  G15,
  G16,
  G17,
  G18,
  G19,
  G20,
  G21,
  G22,
  G23,
  G24,
  G25,
  G26,
  G27,
  G28,
]
