/** @jsx jsx */
import { jsx } from "@emotion/core"
const IconLocation = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8276 22.895C15.7228 21.6136 16.4829 20.5432 17.1292 19.633C18.3194 17.9569 19.1238 16.8241 19.6754 15.9173C20.0949 15.2278 20.3298 14.7277 20.4666 14.2709C20.6011 13.822 20.6552 13.3671 20.6552 12.75C20.6552 9.58509 18.0569 7 14.8276 7C11.5983 7 9 9.58509 9 12.75C9 13.3671 9.05407 13.822 9.18853 14.2709C9.32536 14.7277 9.56032 15.2278 9.97973 15.9173C10.5314 16.8241 11.3358 17.9569 12.526 19.633C13.1723 20.5432 13.9324 21.6136 14.8276 22.895ZM14.1259 23.6368C13.2044 22.3161 12.4167 21.2068 11.7445 20.26C8.64747 15.8981 8 14.9862 8 12.75C8 9.02207 11.0568 6 14.8276 6C18.5984 6 21.6552 9.02207 21.6552 12.75C21.6552 14.9862 21.0077 15.8981 17.9107 20.26C17.2384 21.2068 16.4508 22.3161 15.5293 23.6368C15.1902 24.1211 14.4649 24.121 14.1259 23.6368ZM18.6724 12.75C18.6724 14.8663 16.9402 16.5625 14.8276 16.5625C12.7149 16.5625 10.9828 14.8663 10.9828 12.75C10.9828 10.6337 12.7149 8.9375 14.8276 8.9375C16.9402 8.9375 18.6724 10.6337 18.6724 12.75ZM17.6724 12.75C17.6724 14.3033 16.3987 15.5625 14.8276 15.5625C13.2564 15.5625 11.9828 14.3033 11.9828 12.75C11.9828 11.1967 13.2564 9.9375 14.8276 9.9375C16.3987 9.9375 17.6724 11.1967 17.6724 12.75Z"
        fill="#3A3956"
      />
    </svg>
  )
}
export { IconLocation }
