/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { useTheme } from "emotion-theming"
import { useIntl } from "gatsby-plugin-intl"

const BenefitCard = ({ icon, title, description }) => {
  const theme = useTheme()
  const styles = css`
    padding: 32px 24px 48px;
    border-radius: 4px;

    @media screen and (max-width: 540px) {
      padding: 24px;
      background: white;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1) !important;
      margin: ${theme.margin[300]} ${theme.margin[100]};

      .icon {
        background: ${theme.color.red};
      }
    }

    .icon {
      width: 40px;
      height: 40px;
      margin-bottom: ${theme.margin[200]};
      border-radius: 4px;

      svg {
        width: 100%;
        height: 100%;
        display: block;
      }
    }

    .title {
      font-size: 18px;
      font-weight: bold;
      font-family: "Product Sans", sans-serif;
      margin-bottom: ${theme.margin[100]};
    }

    p {
      font-size: 14px;
    }
  `
  const intl = useIntl()
  return (
    <div className="BenefitCard" css={styles}>
      <div className="icon">{icon}</div>
      <div className="title">{intl.formatMessage({ id: title })}</div>
      <p>{intl.formatMessage({ id: description })}</p>
    </div>
  )
}
export { BenefitCard }
